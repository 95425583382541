import { useContext } from 'react'
import { ConfigContext } from '../ConfigContext'

const useConfig = () => {
  const config = useContext(ConfigContext)

  return config
}

export default useConfig
